import { computed, onBeforeUnmount, ref, watch } from "@vue/composition-api"
import Video from "twilio-video"

import User from "@shared/User"

import { VideoConstraintsType, VideoConstraints } from "@/store/TwilioModule"
import useDrawPoints from "@/use/useDrawPoints"
import useStore from "@/use/useStore"

const SIZE = 300
const IDENTITY_PREFIX = "spiral-drawing"
const CONSTRAINTS = {
  ...VideoConstraints[VideoConstraintsType.USER],
  type: VideoConstraintsType.USER
}

export default function useSpiralDrawing() {
  const { store } = useStore()
  const viewer = computed(() => store.getters["auth/user"])
  const mission = computed(() => store.getters.getCurrentMission)
  const room = computed(() => store.getters.game)
  const roomId = computed(() => store.getters.game?.id)
  const pen = computed(() => store.state.drawing.pen)
  const scribe = computed(() =>
    store.getters["GameUsers/usersOnlineArray"].find(User.isScribe)
  )
  const scribeId = computed(() => scribe.value?.id)
  const identity = computed(() =>
    scribeId.value ? `${IDENTITY_PREFIX}-${scribeId.value}` : null
  )
  const isPresenter = computed(() => User.isPresenter(viewer.value))
  const isViewerHostLike = computed(
    () => store.getters["group/isViewerHostLike"]
  )

  const imageUrl = computed(() => scribe.value?.image)
  const isViewerScribe = computed(() => scribeId.value === viewer.value?.id)

  const drawable = ref(null)
  const width = ref(SIZE)
  const height = ref(SIZE)
  const constraints = ref(CONSTRAINTS)

  const lineWidth = computed(() => store.state.drawing.lineWidth)
  const color = computed(() => store.state.drawing.lineColorRgb)

  const drawingId = computed(() => `${room.value?.id}${mission.value.id}`)

  const sessionId = computed(() =>
    isViewerHostLike.value || isPresenter.value
      ? scribeId.value
      : viewer.value?.id
  )

  const { points, sessionRef } = useDrawPoints(drawingId, sessionId)

  function onMouseUp({ points, lineColor, lineWidth }) {
    sessionRef.value.push({
      data: JSON.stringify({ points, lineColor, lineWidth })
    })
  }

  let animationId

  watch(drawable, value => {
    if (isViewerHostLike.value || !value) return

    const canvasOne = drawable.value?.$refs?.canvas_remote
    const canvasTwo = drawable.value?.$refs?.canvas_local

    const canvas = document.createElement("canvas")
    canvas.width = width.value
    canvas.height = height.value
    const ctx = canvas.getContext("2d")

    const stream = canvas.captureStream(25) // 25 FPS
    const track = new Video.LocalVideoTrack(stream.getTracks()[0])

    function draw() {
      ctx.beginPath()
      ctx.rect(0, 0, width.value, height.value)
      ctx.fillStyle = "#fff"
      ctx.fill()

      ctx.drawImage(canvasOne, 0, 0, width.value, height.value)
      ctx.drawImage(canvasTwo, 0, 0, width.value, height.value)

      animationId = window.requestAnimationFrame(draw)
    }

    animationId = window.requestAnimationFrame(draw)

    store.commit("twilio/UPDATE_VIDEO_TRACK_OVERRIDE", track)
  })

  onBeforeUnmount(() => {
    if (animationId) window.cancelAnimationFrame(animationId)
    store.commit("twilio/UPDATE_VIDEO_TRACK_OVERRIDE", null)
  })

  return {
    scribe,
    points,
    mission,
    color,
    pen,
    onMouseUp,
    isViewerHostLike,
    isPresenter,
    drawable,
    width,
    height,
    identity,
    imageUrl,
    isViewerScribe,
    roomId,
    constraints,
    lineWidth
  }
}
