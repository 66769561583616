import { ref, computed, watch, onBeforeUnmount } from "@vue/composition-api"

import { db } from "@/firebase"

export default function useDrawPoints(drawingId, sessionId) {
  const points = ref({})

  const sessionRef = computed(() =>
    db
      .auxiliary()
      .ref(`drawings/${drawingId.value}/sessions/${sessionId.value}`)
  )

  function onSnapshot(snapshot) {
    points.value = snapshot.val()
  }

  watch(
    sessionRef,
    (newValue, oldValue) => {
      oldValue?.off("value", onSnapshot)
      newValue.on("value", onSnapshot)
    },
    { immediate: true }
  )

  onBeforeUnmount(() => sessionRef.value.off("value", onSnapshot))

  return {
    sessionRef,
    points
  }
}
